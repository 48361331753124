/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useNavigate } from "react-router";
import { useState, useEffect, forwardRef } from "react";
import { NumericFormat } from "react-number-format";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Dialog/Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Data
import DataTable from "examples/Tables/DataTable";
import stockData from "layouts/stock/data/stockData";
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { StockDataRow } from "./data/stockData";
// import "./style.css";
import "../../assets/styles/style.css";
import useStockQRPrint from "./qr-print";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function Stock() {
  const navigate = useNavigate();
  const { columns: pColumns } = stockData(); // create columns var for stock list
  const authorizedReq = useAuthorizedRequest();
  const [refetchToken, setRefetchToken] = useState(null);

  // delete stock dialog props
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // stock dialog props
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const stockDialogTheme = useTheme();
  const stockDialogFullScreen = useMediaQuery(stockDialogTheme.breakpoints.down("md"));

  // receipt details dialog
  const [openReceiptDetailsDialog, setOpenReceiptDetailsDialog] = useState(false);
  const receiptDetailsDialog = useTheme();
  const receiptDetailsDialogFullScreen = useMediaQuery(receiptDetailsDialog.breakpoints.down("md"));

  // save id and name receipt choosed for receipt details dialog
  const [receiptChoosed, setReceiptChoosed] = useState([]);

  // save the receipt details table
  const [receiptDetails, setReceiptDetails] = useState([]);
  const [receiptDetailsData, setReceiptDetailsData] = useState([]);

  // save id and name choosed for edit/delete/dialog
  const [idChoosed, setIdChoosed] = useState(null);
  const [stockChoosed, setStockChoosed] = useState(null);

  // save id and name stock row choosed for stock receipt dialog
  const [idStockReceipt, setIdStockReceipt] = useState(null);
  const [stockReceipt, setStockReceipt] = useState(null);

  // for printing QR Stock
  const [qrPrintData, setQrPrintData] = useState({});
  const { ThingToPrint, handlePrint } = useStockQRPrint(qrPrintData);

  // for storing receipt data related to stock
  const [receiptData, setReceiptData] = useState([]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (i, j) => {
    setOpenDialog(true);
    setIdChoosed(i);
    setStockChoosed(j);
  };

  // snackbar props
  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const handleRowDelete = () => {
    handleCloseDialog();
    authorizedReq
      .delete(`${process.env.REACT_APP_API_BASE_URL}/stock/${idChoosed}`)
      .then(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "success",
          message: `${stockChoosed} Deleted!`,
        });
        setRefetchToken(Date.now());
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Delete Error",
        });
      });
  };

  // const printQR = (prodName, prodTags) => {
  //   setQrPrintData({
  //     product_name: prodName,
  //     tags: prodTags,
  //   });
  // };

  const handleOpenStockDialog = (row) => {
    if (idStockReceipt !== row.original.id.value) {
      setReceiptData([]);
    }
    setOpenStockDialog(true);
    setIdStockReceipt(row.original.id.value);
    setStockReceipt(
      `${row.original.stock_name.value} ${row.original.tags.props.children.props.children}`
    );
    // console.log(row.original);
  };

  const handleCloseStockDialog = () => {
    setOpenStockDialog(false);
  };

  // every stock row clicked, update the receipt data for showing receipt related to stock
  useEffect(() => {
    if (stockReceipt === null && idStockReceipt === null) {
      return;
    }
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/stock/${idStockReceipt}`)
      .then((res) => {
        setReceiptData(res.data.receipts);
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Get Related Receipt Error",
        });
      });
  }, [stockReceipt, idStockReceipt]);

  // print stock every print button clicked
  useEffect(() => {
    if (qrPrintData.product_name) {
      handlePrint();
      setQrPrintData({});
    }
  }, [qrPrintData, ThingToPrint]);

  const handleRowEdit = (i) => {
    navigate(`/stock/stock-edit?id=${i}`);
  };

  const handleCloseReceiptDialog = () => {
    setOpenReceiptDetailsDialog(false);
    // setReceiptDetailsData([]);
  };

  const handleOpenReceiptDialog = (row) => {
    // console.log(row);
    if (receiptChoosed.id !== row.original.id.value) {
      setReceiptDetailsData([]);
      setReceiptDetails([]);
    }
    setOpenReceiptDetailsDialog(true);
    setReceiptChoosed({
      id: row.original.id.value,
      receipt_id: row.original.receipt_name.value,
    });
  };

  useEffect(() => {
    if (receiptChoosed.receipt_id === undefined && receiptChoosed.id === undefined) {
      return;
    }
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/receipt/${receiptChoosed.id}`)
      .then((res) => {
        setReceiptDetails(res.data);
        setReceiptDetailsData(res.data.items);
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Get Receipt Error",
        });
      });
  }, [receiptChoosed.receipt_id, receiptChoosed.id]);

  return (
    <DashboardLayout>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        // onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">Delete {stockChoosed}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to delete {stockChoosed}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton autoFocus onClick={handleCloseDialog} variant="outlined" color="secondary">
            No
          </MDButton>
          <MDButton onClick={handleRowDelete} autoFocus variant="outlined" color="error">
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen={stockDialogFullScreen}
        open={openStockDialog}
        onClose={handleCloseStockDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">{stockReceipt}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={4} mb={1}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Receipt ID", accessor: "receipt_id", width: "20%" },
                      { Header: "Receipt Date", accessor: "date", width: "20%" },
                      { Header: "Total Amount", accessor: "buy_price", width: "20%" },
                      { Header: "Quantity", accessor: "qty", width: "20%" },
                      { Header: "Supplier Name", accessor: "supplier_name", width: "20%" },
                    ],
                    rows: receiptData.map((x) => ({
                      id: {
                        value: x.batch_id,
                        hidden: true,
                      },
                      receipt_name: {
                        value: x.receipt_id,
                        hidden: true,
                      },
                      receipt_id: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.receipt_id}
                          </MDTypography>
                        </MDBox>
                      ),
                      date: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.date}
                          </MDTypography>
                        </MDBox>
                      ),
                      buy_price: (
                        <MDBox display="flex" alignItems="center">
                          {/* <MDTypography display="block" variant="text" fontWeight="medium">
                            {x.buy_price}
                          </MDTypography> */}
                          <NumericFormat
                            value={x.buy_price}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="Rp "
                          />
                        </MDBox>
                      ),
                      qty: (
                        <MDBox display="flex" alignItems="center">
                          {/* <MDTypography display="block" variant="text" fontWeight="medium">
                            {x.qty}
                          </MDTypography> */}
                          <NumericFormat
                            value={x.qty}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      supplier_name: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.supplier_name}
                          </MDTypography>
                        </MDBox>
                      ),
                    })),
                  }}
                  isSorted={false}
                  onRowClick={handleOpenReceiptDialog}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton autoFocus onClick={handleCloseStockDialog} variant="outlined" color="error">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={receiptDetailsDialogFullScreen}
        open={openReceiptDetailsDialog}
        onClose={handleCloseReceiptDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        {/* <DialogTitle id="responsive-dialog-title">{receiptShow.receipt_id}</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={4} mb={1}>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Code : {receiptDetails.receipt_code}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Date : {receiptDetails.date}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Supplier Name : {receiptDetails.supplier_name}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Payment Status : {receiptDetails.payment_status}
                  </MDTypography>
                </MDBox>
                <br />
                <DataTable
                  table={{
                    columns: [
                      { Header: "Stock Name", accessor: "product_name", width: "30%" },
                      { Header: "Stock Tags", accessor: "stock_tags", width: "10%" },
                      { Header: "Quantity Bought", accessor: "qty_bought", width: "20%" },
                      { Header: "Quantity Left", accessor: "qty_left", width: "20%" },
                      { Header: "Buy Price", accessor: "buy_price", width: "10%" },
                      // { Header: "Action", accessor: "action", width: "10%" },
                    ],
                    rows: receiptDetailsData.map((x) => ({
                      item_id: {
                        value: x.item_id,
                        hidden: true,
                      },
                      stock_buy_price: {
                        value: x.buy_price,
                        hidden: true,
                      },
                      stock_product_name: {
                        value: `${x.product_name}, ${x.stock_tags}`,
                        hidden: true,
                      },
                      product_name: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.product_name}
                          </MDTypography>
                        </MDBox>
                      ),
                      stock_tags: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.stock_tags}
                          </MDTypography>
                        </MDBox>
                      ),
                      qty_bought: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_bought}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      qty_left: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_left}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      buy_price: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.buy_price}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="Rp "
                          />
                        </MDBox>
                      ),
                      // action: (
                      //   <MDBox>
                      //     <MDButton
                      //       iconOnly
                      //       color="info"
                      //       onClick={() =>
                      //         handleOpenEditDialog(
                      //           x.stock_id,
                      //           `${x.product_name}, ${x.stock_tags}`,
                      //           x.item_id,
                      //           x.buy_price
                      //         )
                      //       }
                      //       variant="outlined"
                      //     >
                      //       <Icon fontSize="small" component="i" color="info">
                      //         edit
                      //       </Icon>
                      //     </MDButton>
                      //     <MDButton
                      //       iconOnly
                      //       color="error"
                      //       onClick={() =>
                      //         printQR(x.product_name, x.stock_tags, receiptShow.receipt_id)
                      //       }
                      //       variant="outlined"
                      //     >
                      //       <Icon fontSize="small" component="i" color="error">
                      //         print
                      //       </Icon>
                      //     </MDButton>
                      //   </MDBox>
                      // ),
                    })),
                  }}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton autoFocus onClick={handleCloseReceiptDialog} variant="outlined" color="error">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      {/* This is hidden on purpose } */}
      <div style={{ display: "none" }}>{ThingToPrint}</div>
      {/* {TODO this button is to be removed} */}
      {/* <MDButton onClick={() => printQR("test", "test1, test2")}>Print</MDButton> */}

      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Stock
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={3} align="right">
                    <MDButton
                      component="a"
                      onClick={() => navigate("/stock/stock-add")}
                      sx={{ display: { xs: "block", lg: "inline" }, mt: { xs: 2 } }}
                    >
                      Add Stock
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={pColumns}
                  // isSorted
                  queryArgs={{
                    queryKey: "/stock",
                    defaultSortKey: "updated_at",
                    sortDir: "desc",
                  }}
                  refetchToken={refetchToken}
                  onRowDelete={handleOpenDialog}
                  onRowEdit={handleRowEdit}
                  // onRowPrint={printQR}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={StockDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  onRowClick={handleOpenStockDialog}
                  exportData
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Stock;
