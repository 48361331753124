/* eslint-disable no-undef */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NumericFormat } from "react-number-format";

// Material Dashboard 2 React components
// import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Snackbar, OutlinedInput } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useAuthorizedRequest from "components/AuthorizedRequest";
import axios from "axios";
import _ from "lodash";

function ReturnItem() {
  const cancellationSource = axios.CancelToken.source();
  const authorizedReq = useAuthorizedRequest();

  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [fetchedStock, setFetchedStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState({
    item_id: "",
    stock_name: "",
  });

  const [inputReceiptId, setInputReceiptId] = useState("");
  const [fetchedReceiptId, setFetchedReceiptId] = useState([]);
  const [selectedReceiptId, setSelectedReceiptId] = useState({
    id: 0,
  });

  const [amountReturned, setAmountReturned] = useState({
    amount: 0,
  });

  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });

  useEffect(() => {
    let active = true;
    let errMessage = "";
    // if (selectedReceiptId.id === 0) {
    //   setIsSelectDisabled(true);
    //   return undefined;
    // }
    if (
      typeof selectedReceiptId === "undefined" ||
      selectedReceiptId?.id === 0 ||
      typeof selectedReceiptId?.id === "undefined"
    ) {
      setIsSelectDisabled(true);
      return undefined;
    }
    setIsSelectDisabled(false);
    authorizedReq
      .get(`/receipt/${selectedReceiptId.id}`, {
        cancelToken: cancellationSource.token,
      })
      .then((res) => {
        if (active) {
          // let newOptions = [];
          setFetchedStock(
            res.data.items.map((x) => ({
              item_id: x.item_id,
              // stock_id: x.stock_id,
              stock_name: `${x.product_name}, ${x.stock_tags}`,
            }))
          );
        }
      })
      .catch((err) => {
        errMessage = err.cancel;
        if (errMessage);
      });
    return () => {
      cancellationSource.cancel();
      active = false;
    };
  }, [selectedReceiptId]);

  const menuItems = fetchedStock.map((item) => (
    <MenuItem key={item.item_id} value={item.item_id}>
      {item.stock_name}
    </MenuItem>
  ));

  useEffect(() => {
    let active = true;
    let errMessage = "";
    if (inputReceiptId === "") {
      setFetchedReceiptId(selectedReceiptId ? [selectedReceiptId] : [{ id: 0 }]);
      return undefined;
    }

    _.debounce(() => {
      authorizedReq
        .get(`/receipt?search=${inputReceiptId}`, {
          cancelToken: cancellationSource.token,
        })
        .then((res) => {
          if (active) {
            // let newOptions = [];
            setFetchedReceiptId(
              res.data.data.map((x) => ({
                id: x.id,
                receipt_id: x.receipt_id,
              }))
            );
          }
        })
        .catch((err) => {
          errMessage = err.cancel;
          if (errMessage);
        });
    }, 100)();

    return () => {
      cancellationSource.cancel();
      active = false;
    };
  }, [selectedReceiptId, inputReceiptId]);

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleClearData = () => {
    setInputReceiptId("");
    setFetchedReceiptId([]);
    setSelectedReceiptId({
      id: 0,
    });
    setSelectedStock({
      item_id: "",
      stock_name: "",
    });
    setAmountReturned({ amount: 0 });
    setFetchedStock([]);
  };

  const handleSubmitReturnItem = () => {
    authorizedReq
      .post(`/receipt/item/return/${selectedStock.item_id}`, amountReturned, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "Item returned successfully",
          severity: "success",
        });
        handleClearData();
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}`,
          severity: "error",
        });
      });
    return () => {
      cancellationSource.cancel();
      active = false;
    };
  };

  const handleChangeAmount = ({ value }) => {
    setAmountReturned({ amount: Number(value) });
    // console.log(amountReturned);
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitReturnItem();
                  }}
                >
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Receipt ID</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedReceiptId}
                      onChange={(e, d) => setSelectedReceiptId(d)}
                      options={fetchedReceiptId}
                      getOptionLabel={(option) => option.receipt_id || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newinputReceiptId) => {
                        setInputReceiptId(newinputReceiptId);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="receipt id" />}
                    />
                  </MDBox>
                  {/* <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Stock Name</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedStock}
                      onChange={(e, d) => setSelectedStock(d)}
                      options={fetchedStock}
                      getOptionLabel={(option) => option.product_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputStockValue) => {
                        setInputStockValue(newInputStockValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="stock name" />}
                    />
                  </MDBox> */}
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Select Stock</MDTypography>
                    <Select
                      value={selectedStock.item_id}
                      placeholder="stock name"
                      onChange={(e, d) =>
                        setSelectedStock({
                          item_id: d.props.value,
                          stock_name: d.props.children,
                        })
                      }
                      disabled={isSelectDisabled}
                      variant="standard"
                      fullWidth
                      // defaultValue={fetchedStock[0].item_id}
                    >
                      {menuItems}
                    </Select>
                  </MDBox>
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Amount Returned</MDTypography>
                    {/* <MDInput
                      value={amountReturned.amount}
                      onChange={(e) => setAmountReturned({ amount: e.target.value })}
                      type="number"
                      fullWidth
                      placeholder="amount returned"
                      // color="light"
                      // mt={2}
                    /> */}
                    <NumericFormat
                      value={amountReturned.amount}
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeAmount}
                      customInput={OutlinedInput}
                      fullWidth
                      // startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="sell price"
                    />
                  </MDBox>
                  <MDBox mt={4} mb={4}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmitReturnItem}
                    >
                      Return Item
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReturnItem;
