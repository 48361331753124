/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Product from "layouts/product";
import ProductAdd from "layouts/product/product-add";
import ProductEdit from "layouts/product/product-edit";
import Category from "layouts/category";
import CategoryAdd from "layouts/category/category-add";
import CategoryEdit from "layouts/category/category-edit";
import Stock from "layouts/stock";
import StockAdd from "layouts/stock/stock-add";
import StockEdit from "layouts/stock/stock-edit";
import Supplier from "layouts/supplier";
import SupplierAdd from "layouts/supplier/supplier-add";
import SupplierEdit from "layouts/supplier/supplier-edit";
import UserManagement from "layouts/user-management";
import UserAdd from "layouts/user-management/user-management-add";
import UserEdit from "layouts/user-management/users-management-edit";
import Tag from "layouts/tag";
import TagAdd from "layouts/tag/tag-add";
import TagEdit from "layouts/tag/tag-edit";
import Sales from "layouts/sales";
import SalesReturn from "layouts/sales/sales-return";
import Warehouse from "layouts/warehouse";
import Restock from "layouts/warehouse/need-restock";
import Receipt from "layouts/warehouse/receipt";
import ReceiptPayment from "layouts/warehouse/receipt-payment";
import NewPayment from "layouts/warehouse/receipt-payment/new-payment";
import InputReceipt from "layouts/warehouse/receipt/input-receipt";
import ReturnItem from "layouts/warehouse/receipt/return-item";
// import EditReceipt from "layouts/warehouse/edit-receipt";
// import AdjustStock from "layouts/warehouse/adjust-stock";

// @mui icons
import Icon from "@mui/material/Icon";
// import EventNoteIcon from "@mui/icons-material/EventNote";
// import { QrCode } from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/dashboard",
    roles: ["operator", "admin"],
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Warehouse",
    key: "warehouse",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/warehouse",
    component: <Warehouse />,
  },
  {
    type: "route",
    name: "Receipt",
    key: "receipt",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/receipt",
    component: <Receipt />,
  },
  {
    type: "route",
    name: "Receipt Payment",
    key: "receipt-payment",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/receipt-payment",
    component: <ReceiptPayment />,
  },
  {
    type: "route",
    name: "New Payment",
    key: "new-payment",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/receipt-payment/new-payment",
    component: <NewPayment />,
  },
  {
    type: "route",
    name: "Input Receipt",
    key: "input-receipt",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/receipt/input-receipt",
    component: <InputReceipt />,
  },
  {
    type: "route",
    name: "Return Item",
    key: "return-item",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/receipt/return-item",
    component: <ReturnItem />,
  },
  // {
  //   type: "route",
  //   name: "Edit Receipt",
  //   key: "edit-receipt",
  //   icon: <Icon fontSize="small">inventory</Icon>,
  //   route: "/warehouse/edit-receipt",
  //   component: <EditReceipt />,
  // },
  // {
  //   type: "route",
  //   name: "Adjust Stock",
  //   key: "adjust-stock",
  //   icon: <Icon fontSize="small">inventory</Icon>,
  //   route: "/warehouse/adjust-stock",
  //   component: <AdjustStock />,
  // },
  {
    type: "route",
    name: "Restock",
    key: "restock",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/warehouse/restock",
    component: <Restock />,
  },
  {
    type: "collapse",
    name: "Stock",
    key: "stock",
    icon: <Icon fontSize="small">local_library</Icon>,
    route: "/stock",
    component: <Stock />,
  },
  {
    type: "route",
    name: "Add Stock",
    key: "stock-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/stock/stock-add",
    component: <StockAdd />,
  },
  {
    type: "route",
    name: "Edit Stock",
    key: "stock-edit",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/stock/stock-edit",
    component: <StockEdit />,
  },
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/product",
    component: <Product />,
  },
  {
    type: "route",
    name: "Add Product",
    key: "product-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/product/product-add",
    component: <ProductAdd />,
  },
  {
    type: "route",
    name: "Edit Product",
    key: "product-edit",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/product/product-edit",
    component: <ProductEdit />,
  },
  {
    type: "collapse",
    name: "Category",
    key: "category",
    icon: <Icon fontSize="small">collections_bookmark</Icon>,
    route: "/category",
    component: <Category />,
  },
  {
    type: "route",
    name: "Add Category",
    key: "category-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/category/category-add",
    component: <CategoryAdd />,
  },
  {
    type: "route",
    name: "Edit Category",
    key: "category-edit",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/category/category-edit",
    component: <CategoryEdit />,
  },
  {
    type: "collapse",
    name: "Supplier",
    key: "supplier",
    icon: <Icon fontSize="small">directions_bike</Icon>,
    route: "/supplier",
    component: <Supplier />,
  },
  {
    type: "route",
    name: "Add Supplier",
    key: "supplier-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/supplier/supplier-add",
    component: <SupplierAdd />,
  },
  {
    type: "route",
    name: "Edit Supplier",
    key: "supplier-edit",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/supplier/supplier-edit",
    component: <SupplierEdit />,
  },
  {
    type: "collapse",
    name: "Tag",
    key: "tag",
    icon: <Icon fontSize="small">sell</Icon>,
    route: "/tag",
    component: <Tag />,
  },
  {
    type: "route",
    name: "Add Tag",
    key: "tag-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/tag/tag-add",
    component: <TagAdd />,
  },
  {
    type: "route",
    name: "Edit Tag",
    key: "tag-edit",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/tag/tag-edit",
    component: <TagEdit />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  },
  {
    type: "route",
    name: "Add User",
    key: "user-add",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/user-management/user-add",
    component: <UserAdd />,
  },
  {
    type: "route",
    name: "Edit User",
    key: "user-edit",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/user-management/user-edit",
    component: <UserEdit />,
  },
  // {
  //   type: "collapse",
  //   name: "QR Generate",
  //   key: "qr-generate",
  //   // icon: <QrCode fontSize="small" />,
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/qr-generate",
  //   component: <QRAdd />,
  // },
  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    // icon: <QrCode fontSize="small" />,
    icon: (
      <Icon fontSize="small">
        {/* <EventNoteIcon /> */}
        shopping_cart
      </Icon>
    ),
    route: "/sales",
    component: <Sales />,
  },
  {
    type: "route",
    name: "Sales Return",
    key: "sales-return",
    route: "/sales/return",
    component: <SalesReturn />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "hide",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
