import React from "react";
import { Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { PropTypes } from "prop-types";

function redirectToLogin() {
  localStorage.clear();
  return <Navigate to="/authentication/sign-in" />;
}

function RbacCheckAccess(roles, accessRoles) {
  // Check if user have every roles needed to access this page
  return accessRoles.every((x) => roles.indexOf(x) >= 0);
}

function ProtectedRoute({ roles: accessRoles, children }) {
  const userToken = localStorage.getItem("access_token");
  try {
    const tokenData = userToken && jwtDecode(userToken);
    const userRoles = tokenData && tokenData.roles;
    // Check if token expired
    const tokenValid = tokenData && tokenData.exp > Math.floor(new Date() / 1000);
    if (!userToken || !userRoles || !tokenValid || !RbacCheckAccess(userRoles, accessRoles || []))
      return redirectToLogin();
  } catch (e) {
    return redirectToLogin();
  }
  return children;
}

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoute;
export { RbacCheckAccess, redirectToLogin };
