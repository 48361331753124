import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Cut, Line, Printer, Row, Image, render } from "react-thermal-printer";
// eslint-disable-next-line
import { connect } from "net";
import moment from "moment";
import "moment/locale/id";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import ClearIcon from "@mui/icons-material/Clear";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import beepAudio from "assets/sounds/beep.mp3";
import { Howl } from "howler";
import _ from "lodash";
import useAuthorizedRequest from "components/AuthorizedRequest";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FullScreenSpinner from "components/FullScreenSpinner";
import SanxuLogo from "../../assets/images/logos/sanxu-logo.png";
import renderSalesDataList from "./sales-data";

function Sales() {
  const navigate = useNavigate();
  moment.locale("id");
  const beep = new Howl({
    src: [beepAudio],
  });
  const authorizedRequester = useAuthorizedRequest();
  const [BTDevice, setBTDevice] = useState("");
  const [qrData, setQrData] = useState("");
  const [errorMessage] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [selectedScanner, setSelectedScanner] = useState("External Scanner");
  const [scannerRemountToken, setScannerRemountToken] = useState(Date.now());
  // const [scannerInput, setScannerInput] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [confirmSubmit, setConfirmSubmit] = useState({
    showDialog: false,
    isSubmitting: false,
    snackMessage: "",
    alertSeverity: "info",
    showAlert: false,
  });
  const [confirmDelete, setConfirmDelete] = useState({
    delStockId: 0,
    delIndex: -1,
    showDialog: false,
  });
  const tempQRData = useRef();
  const [transactionMediaValue, setTransactionMediaValue] = useState("Online");
  const [paymentChannel, setPaymentChannel] = useState("");
  const [showPrintAndReset, setShowPrintAndReset] = useState(false);
  const [salesOperator, setSalesOperator] = useState("");
  const receiptData = useMemo(
    () => (
      <>
        <Printer type="epson" characterSet="pc437_usa">
          <Image align="center" width={30} src={SanxuLogo} />
          <Line />
          <Row left="Alamat: " right="Mampang Prapatan Jakarta Selatan saja" />
          <Row left="Tanggal/Hari: " right={moment().format("llll")} />
          <Row left="No. WhatsApp: " right="081919332255" />
          <Row left="Payment Channel: " right={paymentChannel?.label || ""} />
          <Row left="Kasir: " right={salesOperator || ""} />
          <Line />
          <Row left="Item" center="Qty" right="Total" />
          <Line />
          {salesData.map((data, idx) => (
            <>
              <div key={`item-row-${idx + 1}`}>
                <Row
                  left={`${data?.product_name?.substring?.(0, 20)}` || ""}
                  center={`x${data.qty}`}
                  right={`${data?.sell_price * data?.qty}` || 0}
                />
                {data.discount > 0 && (
                  <Row
                    key={`discount-row-${idx + 1}`}
                    left=""
                    right={`(- ${data?.discount * data?.qty})` || 0}
                  />
                )}
              </div>
            </>
          ))}
          <Line />
          <Row
            left="Sub Total: "
            center=""
            right={`${salesData.reduce((total, data) => total + data.sell_price * data.qty, 0)}`}
          />
          {salesData.reduce((total, data) => total + data.discount * data.qty, 0) > 0 && (
            <Row
              left="Discount: "
              right={`(- ${salesData.reduce(
                (total, data) => total + data.discount * data.qty,
                0
              )})`}
            />
          )}
          <Row
            left="Grand Total: "
            right={`${
              salesData.reduce((total, data) => total + data.sell_price * data.qty, 0) -
              salesData.reduce((total, data) => total + data.discount * data.qty, 0)
            }`}
          />
          <Cut />
        </Printer>
      </>
    ),
    [salesData, salesOperator]
  );

  const handleBluetoothClick = async () => {
    console.log("Requesting Bluetooth Device...");
    const gattServer = await navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [
              "0000fff0-0000-1000-8000-00805f9b34fb",
              "0000feea-0000-1000-8000-00805f9b34fb",
            ],
          },
          { namePrefix: "BarCode" },
        ],
        optionalServices: [
          "0000fff0-0000-1000-8000-00805f9b34fb",
          "0000feea-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        console.log("Connecting to GATT Server...");
        setBTDevice(device.name);
        return device.gatt.connect();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });

    let targetCharacteristic = "";
    let primaryService = await gattServer
      .getPrimaryService("0000fff0-0000-1000-8000-00805f9b34fb")
      .catch((e) => console.log(e));
    targetCharacteristic = "0000fff1-0000-1000-8000-00805f9b34fb";
    if (!primaryService?.uuid) {
      primaryService = await gattServer.getPrimaryService("0000feea-0000-1000-8000-00805f9b34fb");
      targetCharacteristic = "00002aa1-0000-1000-8000-00805f9b34fb";
    }
    primaryService
      .getCharacteristic(targetCharacteristic)
      .then((characteristic) => {
        characteristic.addEventListener("gattserverdisconnected", () => setBTDevice(""));
        characteristic.addEventListener("characteristicvaluechanged", (e) => {
          const dec = new TextDecoder("utf-8");
          const decodedData = dec.decode(e.target.value.buffer);
          tempQRData.current = tempQRData.current
            ? (tempQRData.current += decodedData)
            : decodedData;
          if (/\r|\n/.exec(decodedData)) {
            // Do something, the string contains a line break
            setQrData(tempQRData.current.slice(0, -1));
            tempQRData.current = "";
          }
        });
        characteristic.startNotifications();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });
  };

  const handleQtyChange = (itemId, qty) => {
    const saleDataIdx = _.findIndex(salesData, (x) => x.item_id === itemId);
    const newSalesData = [...salesData];
    newSalesData[saleDataIdx].qty = qty;
    setSalesData(newSalesData);
  };

  const handleDiscountChange = (itemId, amount) => {
    const saleDataIdx = _.findIndex(salesData, (x) => x.item_id === itemId);
    const newSalesData = [...salesData];
    newSalesData[saleDataIdx].discount = amount;
    setSalesData(newSalesData);
  };

  const handleConfirmDelete = (itemId) => {
    const saleDataIdx = _.findIndex(salesData, (x) => x.item_id === itemId);
    setConfirmDelete({
      delStockId: itemId,
      delIndex: saleDataIdx,
      showDialog: true,
    });
  };

  const handleCancelDelete = () => {
    setConfirmDelete({
      delStockId: 0,
      delIndex: -1,
      showDialog: false,
    });
  };

  const handleDoDelete = () => {
    setConfirmDelete({ ...confirmDelete, showDialog: false });
    setSalesData(_.reject(salesData, (x) => x.item_id === confirmDelete.delStockId));
  };

  const handleQRScanResult = async (qrErr, qrRes) => {
    if (qrRes) {
      beep.play();
      setQrData(qrRes?.text);
    }

    if (qrErr) {
      // setIsError(true);
      // handleSetErrorMessage(qrErr);
      // console.info(qrErr);
      setQrData("");
    }
  };

  const resetSales = () => {
    setTransactionId("");
    setSalesData([]);
    setPaymentChannel("");
    setShowPrintAndReset(false);
  };

  const printReceipt = async () => {
    console.log("Print Receipt");

    const renderedReceipt = await render(receiptData);
    const conn = connect(
      {
        host: "192.168.1.200",
        port: 9100,
        timeout: 3000,
      },
      () => {
        conn.write(Buffer.from(renderedReceipt), () => {
          conn.destroy();
        });
      }
    );
  };

  const proceedTransaction = async (e) => {
    e.preventDefault();
    setConfirmSubmit({ ...confirmSubmit, isSubmitting: true, showDialog: true });
    authorizedRequester
      .post("sales", {
        store_id: 1,
        transaction_id: transactionId,
        transaction_media: transactionMediaValue,
        payment_channel: paymentChannel.id,
        items: salesData.map((x) => ({
          stock_id: x.item_id,
          discount: x.discount,
          sell_price: x.sell_price,
          qty: x.qty,
        })),
      })
      .then((resp) => {
        setConfirmSubmit({
          ...confirmSubmit,
          showAlert: true,
          snackMessage: "Transaction Saved",
          alertSeverity: "success",
        });
        setSalesOperator(resp?.data?.operator);
        if (transactionMediaValue === "Online") {
          resetSales();
        } else {
          setShowPrintAndReset(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Insufficient Stock") {
          console.log("Insufficient Stock");
          console.log(err.response?.data?.data);
          setSalesData(
            salesData.map((data) => ({
              ...data,
              err_message: err.response?.data?.data?.includes(data.item_id)
                ? "Insufficient Stock"
                : "",
            }))
          );
          setConfirmSubmit({
            ...confirmSubmit,
            showAlert: true,
            snackMessage: "Insufficient Stock",
            alertSeverity: "error",
          });
        } else {
          setConfirmSubmit({
            ...confirmSubmit,
            showAlert: true,
            snackMessage: JSON.stringify(err.response.data),
            alertSeverity: "error",
          });
        }
      });
    // TODO set alerts
    setConfirmSubmit({ ...confirmSubmit, isSubmitting: false, showDialog: false });
  };

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, [selectedScanner]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, []);

  useEffect(() => {
    if (!qrData) return;
    // The scan is for transaction ID if not has set
    if (!transactionId && transactionMediaValue === "Online") {
      setTransactionId(qrData);
      return;
    }

    authorizedRequester
      .get(`/receipt/item/${qrData}`)
      .then((res) => {
        if (!res.data.item_id) {
          // use confirm submit alert???????
          setConfirmSubmit({
            snackMessage: "Stock Not Found",
            alertSeverity: "error",
            showAlert: true,
          });
          return;
        }
        setQrData("");

        let existedIndex = -1;
        if (salesData.length) {
          existedIndex = _.findIndex(salesData, (x) => res.data.item_id === x.item_id);
        }
        if (existedIndex >= 0) {
          const newSalesData = [...salesData];
          newSalesData[existedIndex].qty += 1;
          setSalesData(newSalesData);
        } else {
          const newSalesData = [...salesData];
          newSalesData.push({ ...res.data, qty: 1, discount: 0 });
          setSalesData(newSalesData);
        }
      })
      .catch((err) => {
        setConfirmSubmit({
          snackMessage: err?.message,
          alertSeverity: "error",
          showAlert: true,
        });
      });
  }, [qrData, salesData, setSalesData, setQrData]);

  return (
    <DashboardLayout>
      {receiptData}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={confirmSubmit.showAlert}
        onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
          severity={confirmSubmit.alertSeverity}
          sx={{ width: "100%" }}
        >
          {confirmSubmit.snackMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={confirmSubmit.isSubmitting} />
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        // TransitionProps={{ onEntering: handleEntering }}
        open={confirmDelete.showDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <MDTypography variant="button">
            {confirmDelete.showDialog
              ? `Delete ${salesData[confirmDelete.delIndex].product_name}: ${
                  salesData[confirmDelete.delIndex].stock_tags
                }`
              : ""}
          </MDTypography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button onClick={handleDoDelete}>Ok</Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Sales
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={3} align="right">
                    <MDButton
                      component="a"
                      onClick={() => navigate("/sales/return")}
                      sx={{ display: { xs: "block", lg: "inline" }, mt: { xs: 2 } }}
                    >
                      Sales Return
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text" style={{ fontSize: "0.7em" }}>
                      Transaction Media
                    </MDTypography>
                    <RadioGroup
                      aria-labelledby="Transaction Media"
                      value={transactionMediaValue}
                      name="transaction-media-group"
                      row
                      onChange={(e) => setTransactionMediaValue(e.target.value)}
                    >
                      <FormControlLabel value="Online" control={<Radio />} label="Online" />
                      <FormControlLabel value="Offline" control={<Radio />} label="Offline" />
                    </RadioGroup>
                    {transactionMediaValue === "Online" && (
                      <>
                        <MDTypography variant="text" style={{ fontSize: "0.7em" }}>
                          Transaction ID
                        </MDTypography>
                        <MDInput
                          type="input"
                          fullWidth
                          placeholder="Transaction ID"
                          value={transactionId}
                          // onChange={setQrData(data)}
                          readOnly
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="medium"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setTransactionId("");
                                  }}
                                  sx={{ zIndex: 999 }}
                                >
                                  <Icon fontSize="small" component="i">
                                    close
                                  </Icon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                    {transactionMediaValue === "Offline" && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="payment-options"
                          onChange={(e, value) => setPaymentChannel(value)}
                          options={[
                            { label: "Cash", id: "CASH" },
                            { label: "Bank BCA", id: "BANK_BCA" },
                            { label: "BANK BRI", id: "BANK_BRI" },
                          ]}
                          sx={{ width: "100%", input: { height: "0.85em" } }}
                          size="medium"
                          renderInput={(params) => (
                            <TextField {...params} label="Payment Options" />
                          )}
                        />
                      </>
                    )}
                    <MDBox
                      mt={1}
                      component="form"
                      role="form"
                      sx={{ display: selectedScanner === "External Scanner" ? "block" : "none" }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        setQrData(e.target.scannerInputField.value);
                        e.target.scannerInputField.value = "";
                        // setScannerInput("");
                      }}
                    >
                      <MDInput
                        type="input"
                        fullWidth
                        name="scannerInputField"
                        placeholder="Bluetooth QR and BarCode Scanner"
                        // value={scannerInput}
                        // onChange={(e) => setScannerInput(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <FormControl>
                        <FormLabel id="ScannerSelect">Select Scanner</FormLabel>
                        <RadioGroup
                          name="ScannerSelector"
                          value={selectedScanner}
                          row
                          onChange={(e) => setSelectedScanner(e.target.value)}
                        >
                          <FormControlLabel
                            value="External Scanner"
                            control={<Radio />}
                            label="External Scanner"
                          />
                          <FormControlLabel
                            value="Camera/Webcam"
                            control={<Radio />}
                            label="Camera/Webcam"
                          />
                        </RadioGroup>
                      </FormControl>
                      {selectedScanner === "External Scanner" ? (
                        <>
                          <MDBox mb={1}>
                            <Button
                              onClick={handleBluetoothClick}
                              variant="contained"
                              sx={{ mt: 2 }}
                              endIcon={<BluetoothConnectedIcon color="white" fontSize="large" />}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                size="small"
                              >
                                Connect Bluetooth
                              </MDTypography>
                            </Button>
                          </MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="light"
                            color="info"
                            sx={{ mt: 1, ml: 1 }}
                          >
                            {BTDevice ? `Connected: ${BTDevice}` : "Disconnected"}
                          </MDTypography>
                        </>
                      ) : null}
                    </MDBox>
                    <Button
                      onClick={proceedTransaction}
                      variant="contained"
                      sx={{ width: "100%", mt: 2 }}
                      endIcon={<SendIcon color="white" fontSize="large" />}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        size="small"
                      >
                        Proceed Transaction
                      </MDTypography>
                    </Button>
                    {showPrintAndReset && (
                      <>
                        <Button
                          onClick={printReceipt}
                          variant="contained"
                          sx={{ width: "100%", mt: 2 }}
                          endIcon={<PrintIcon color="white" fontSize="large" />}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="white"
                            size="small"
                          >
                            Print Receipt
                          </MDTypography>
                        </Button>
                        <Button
                          onClick={resetSales}
                          variant="contained"
                          sx={{ width: "100%", mt: 2 }}
                          endIcon={<ClearIcon color="white" fontSize="large" />}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="white"
                            size="small"
                          >
                            Reset Sales
                          </MDTypography>
                        </Button>
                      </>
                    )}
                  </MDBox>
                  {selectedScanner === "Camera/Webcam" ? (
                    <MDBox mt={1} mb={1}>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                          <BarcodeScannerComponent
                            key={scannerRemountToken}
                            style={{ maxWidth: "100%" }}
                            delay={500}
                            onUpdate={handleQRScanResult}
                            stopStream={selectedScanner !== "Camera/Webcam"}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1}>
                    {renderSalesDataList(
                      salesData,
                      handleQtyChange,
                      handleDiscountChange,
                      handleConfirmDelete,
                      transactionMediaValue === "Offline"
                    )}
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDTypography variant="text" hidden>
                      {errorMessage}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Sales;
