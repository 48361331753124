/* eslint-disable react/prop-types */

// @mui icons
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

export default function data() {
  const User = ({ email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="regular" textAlign="left">
        {email}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ title }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="regular" textAlign="left">
        {title}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "user", accessor: "user", align: "left" },
      { Header: "role", accessor: "role", align: "left" },
      // { Header: "status", accessor: "status", align: "center" },
      { Header: "created at", accessor: "createdAt", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        user: <User email="email1@email.com" />,
        role: <Job title="admin" />,
        createdAt: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="regular" textAlign="left">
              11-01-2022 13:37:50
            </MDTypography>
          </MDBox>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./user-management/user-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" variant="outlined" href="./user-management/user-edit">
              <Icon fontSize="small" component="i" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        user: <User email="email2@email.com" />,
        role: <Job title="user" />,
        createdAt: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="regular" textAlign="left">
              12-01-2022 09:11:03
            </MDTypography>
          </MDBox>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./user-management/user-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" variant="outlined" href="./user-management/user-edit">
              <Icon fontSize="small" component="i" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}
