/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import { OutlinedInput } from "@mui/material";
// import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function UserAdd() {
  const [role, setRole] = useState(1);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography>Email</MDTypography>
                    <MDInput type="email" fullWidth placeholder="email" />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Role</MDTypography>
                    <Select
                      labelId="role"
                      id="role"
                      value={role}
                      label="role"
                      variant="standard"
                      defaultValue=""
                      onChange={handleRoleChange}
                      fullWidth
                    >
                      <MenuItem value={1}>Admin</MenuItem>
                      <MenuItem value={0}>Operator</MenuItem>
                    </Select>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Password</MDTypography>
                    <MDInput type="password" fullWidth placeholder="Password" />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Confirm Password</MDTypography>
                    <MDInput type="password" fullWidth placeholder="confirm password" />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="success" fullWidth>
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserAdd;
