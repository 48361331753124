/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
// import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import TextField from "@mui/material/TextField";
// import { OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { parseUrl } from "query-string";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useAuthorizedRequest from "components/AuthorizedRequest";
// import { DropzoneArea } from "react-mui-dropzone";
import { Alert, Snackbar, OutlinedInput } from "@mui/material";
// import { Alert, Snackbar } from "@mui/material";

function StockEdit() {
  const authorizedReq = useAuthorizedRequest();
  const urlPage = parseUrl(window.location.href);
  const stockId = urlPage.query.id;
  // const urlPage = useLocation();
  // const stockId = urlPage.search.charAt(4);
  const navigate = useNavigate();

  const [stockStatus, setStockStatus] = useState("inactive");
  const [selectedTags, setSelectedTags] = useState("");
  const [productName, setProductName] = useState("");
  // const [buyPrice, setBuyPrice] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [stockQty, setStockQty] = useState("");
  // const [filesToUpload, setFilesToUpload] = useState([]);
  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });

  const [stockEditData, setStockEditData] = useState({
    status: "inactive",
    sell_price: 0,
  });

  useEffect(() => {
    setStockEditData({
      status: stockStatus,
      sell_price: sellPrice,
    });
  }, [stockStatus, sellPrice]);

  useEffect(() => {
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/stock/${stockId}`)
      .then((resStock) => {
        setProductName(resStock.data.product_name);
        // setBuyPrice(resStock.data.buy_price);
        setSellPrice(resStock.data.sell_price);
        setStockStatus(resStock.data.status);
        setSelectedTags(resStock.data.tags);
        setStockQty(resStock.data.qty);
      })
      .catch((errStock) => {
        setSnackProps({
          isOpen: true,
          message: `${errStock.response.data.message}`,
          severity: "error",
        });
      });
  }, []);

  const handleStatusChange = (event) => {
    setStockStatus(event.target.value);
  };

  const handleUpdateStock = () => {
    authorizedReq
      .put(`${process.env.REACT_APP_API_BASE_URL}/stock/${stockId}`, stockEditData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "Stock Updated",
          severity: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.message}`,
          severity: "error",
        });
      });
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleChangeSellPrice = ({ value }) => {
    setSellPrice(value);
    // console.log(sellPrice);
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateStock();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="text">Stock Name</MDTypography>
                    <MDInput
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      type="input"
                      fullWidth
                      placeholder="Stock Name"
                      disabled
                    />
                  </MDBox>
                  {/* <MDBox mb={2}>
                    <MDTypography variant="text">Buy Price</MDTypography>
                    <OutlinedInput
                      value={buyPrice}
                      type="number"
                      onChange={(e) => setBuyPrice(e.target.value)}
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      placeholder="buy price"
                      disabled
                    />
                </MDBox> */}
                  <MDBox mb={2}>
                    <MDTypography variant="text">Sell Price</MDTypography>
                    <NumericFormat
                      value={sellPrice}
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeSellPrice}
                      customInput={OutlinedInput}
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="sell price"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Quantity</MDTypography>
                    <NumericFormat
                      value={stockQty}
                      thousandSeparator="."
                      decimalSeparator=","
                      customInput={OutlinedInput}
                      fullWidth
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="quantity"
                      disabled
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Tags</MDTypography>
                    <MDInput
                      value={selectedTags}
                      onChange={(e) => setSelectedTags(e.target.value)}
                      type="text"
                      fullWidth
                      placeholder="Tags"
                      disabled
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Status</MDTypography>
                    <Select
                      labelId="status"
                      id="status"
                      value={stockStatus}
                      label="status"
                      variant="standard"
                      defaultValue=""
                      onChange={handleStatusChange}
                      fullWidth
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={handleUpdateStock}>
                      Update
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StockEdit;
