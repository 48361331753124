import React, { useEffect, useRef, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
// import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import beepAudio from "assets/sounds/beep.mp3";
import { Howl } from "howler";
// import useAuthorizedRequest from "components/AuthorizedRequest";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FullScreenSpinner from "components/FullScreenSpinner";
import useAuthorizedRequest from "components/AuthorizedRequest";

function Sales() {
  const beep = new Howl({
    src: [beepAudio],
  });
  const authorizedRequester = useAuthorizedRequest();
  const [BTDevice, setBTDevice] = useState("");
  const [qrData, setQrData] = useState("");
  const [errorMessage] = useState("");
  const [selectedScanner, setSelectedScanner] = useState("External Scanner");
  const [scannerRemountToken, setScannerRemountToken] = useState(Date.now());
  const [submissionRequested, setSubmissionRequested] = useState(false);
  // const [scannerInput, setScannerInput] = useState("");
  const [transactionData, setTransactionData] = useState({
    transaction_id: "",
  });
  const [confirmSubmit, setConfirmSubmit] = useState({
    showDialog: false,
    isValidating: false,
    isSubmitting: false,
    snackMessage: "",
    alertSeverity: "info",
    showAlert: false,
  });
  const tempQRData = useRef();

  const handleBluetoothClick = async () => {
    console.log("Requesting Bluetooth Device...");
    navigator.bluetooth
      .requestDevice({ filters: [{ services: ["0000fff0-0000-1000-8000-00805f9b34fb"] }] })
      .then((device) => {
        console.log("Connecting to GATT Server...");
        setBTDevice(device.name);
        return device.gatt.connect();
      })
      .then((server) => server.getPrimaryService("0000fff0-0000-1000-8000-00805f9b34fb"))
      .then((service) => service.getCharacteristic("0000fff1-0000-1000-8000-00805f9b34fb"))
      .then((characteristic) => {
        characteristic.addEventListener("gattserverdisconnected", () => setBTDevice(""));
        characteristic.addEventListener("characteristicvaluechanged", (e) => {
          const dec = new TextDecoder("utf-8");
          const decodedData = dec.decode(e.target.value.buffer);
          console.log(decodedData);
          tempQRData.current = tempQRData.current
            ? (tempQRData.current += decodedData)
            : decodedData;
          if (/\r|\n/.exec(decodedData)) {
            // Do something, the string contains a line break
            setQrData(tempQRData.current.slice(0, -1));
            tempQRData.current = "";
            console.log("breaked");
          }
        });
        characteristic.startNotifications();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });
  };

  const handleQRScanResult = async (qrErr, qrRes) => {
    if (qrRes) {
      beep.play();
      setQrData(qrRes?.text);
    }

    if (qrErr) {
      // setIsError(true);
      // handleSetErrorMessage(qrErr);
      // console.info(qrErr);
      setQrData("");
    }
  };

  const proceedReturn = async (e) => {
    e.preventDefault();
    setConfirmSubmit({ ...confirmSubmit, isSubmitting: true });
    authorizedRequester
      .post(`${process.env.REACT_APP_API_BASE_URL}/sales/return`, transactionData)
      .then(() => {
        setConfirmSubmit({
          ...confirmSubmit,
          isSubmitting: false,
          showDialog: false,
          snackMessage: "Sales Returned OK",
          showAlert: true,
          alertSeverity: "success",
          salesId: 0,
        });
        setTransactionData({
          transaction_id: "",
        });
      })
      .catch(() => {
        setConfirmSubmit({
          ...confirmSubmit,
          isSubmitting: false,
          showDialog: false,
          snackMessage: "Failed to return sales",
          showAlert: true,
          alertSeverity: "error",
        });
      });
    setTransactionData({
      transaction_id: "",
    });
  };

  const handleSubmit = async () => {
    setConfirmSubmit({ ...confirmSubmit, isValidating: true });
    authorizedRequester
      .get(`${process.env.REACT_APP_API_BASE_URL}/sales/${transactionData.transaction_id}`)
      .then((res) => {
        if (res.data?.id) {
          setConfirmSubmit({
            ...confirmSubmit,
            isValidating: false,
            showDialog: true,
            salesId: res.data?.id,
          });
          setTransactionData({
            transaction_id: res.data?.transaction_id,
          });
        } else {
          throw new Error("Not Found Error");
        }
      })
      .catch(() => {
        setConfirmSubmit({
          ...confirmSubmit,
          isValidating: false,
          showAlert: true,
          alertSeverity: "error",
          snackMessage: "Transaction ID Not Found!",
        });
      });
  };

  useEffect(() => {
    if (!submissionRequested) return;
    handleSubmit();
    setSubmissionRequested(false);
  }, [submissionRequested]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, [selectedScanner]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, []);

  useEffect(() => {
    if (!qrData) return;
    setTransactionData({
      transaction_id: qrData,
    });
  }, [qrData, setQrData, setTransactionData]);

  return (
    <DashboardLayout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={confirmSubmit.showAlert}
        onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
          severity={confirmSubmit.alertSeverity}
          sx={{ width: "100%" }}
        >
          {confirmSubmit.snackMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={confirmSubmit.isSubmitting} />
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={confirmSubmit.showDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <MDTypography variant="button">
            {confirmSubmit.showDialog ? `Delete ${transactionData.transaction_id}` : ""}
          </MDTypography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmSubmit({ ...confirmSubmit, showDialog: false });
            }}
          >
            Cancel
          </Button>
          <Button onClick={proceedReturn}>Ok</Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Transaction ID</MDTypography>
                    <MDInput
                      type="input"
                      fullWidth
                      placeholder="input text"
                      value={qrData}
                      // onChange={setQrData(data)}
                      readOnly
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="medium"
                              onClick={(e) => {
                                e.preventDefault();
                                setTransactionData({
                                  qrData: "",
                                });
                              }}
                              sx={{ zIndex: 999 }}
                            >
                              <Icon fontSize="small" component="i">
                                close
                              </Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <MDBox
                      mt={1}
                      component="form"
                      role="form"
                      sx={{ display: selectedScanner === "External Scanner" ? "block" : "none" }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        // console.log(e.target.scannerInputField.value);
                        setQrData(e.target.scannerInputField.value);
                        e.target.scannerInputField.value = "";
                        // setScannerInput("");
                      }}
                    >
                      <MDInput
                        type="input"
                        fullWidth
                        name="scannerInputField"
                        placeholder="Bluetooth QR and BarCode Scanner"
                        // value={scannerInput}
                        // onChange={(e) => setScannerInput(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <FormControl>
                        <FormLabel id="ScannerSelect">Select Scanner</FormLabel>
                        <RadioGroup
                          name="ScannerSelector"
                          value={selectedScanner}
                          row
                          onChange={(e) => setSelectedScanner(e.target.value)}
                        >
                          <FormControlLabel
                            value="External Scanner"
                            control={<Radio />}
                            label="External Scanner"
                          />
                          <FormControlLabel
                            value="Camera/Webcam"
                            control={<Radio />}
                            label="Camera/Webcam"
                          />
                        </RadioGroup>
                      </FormControl>
                      {selectedScanner === "External Scanner" ? (
                        <>
                          <MDBox mb={1}>
                            <Button
                              onClick={handleBluetoothClick}
                              variant="contained"
                              sx={{ mt: 2 }}
                              endIcon={<BluetoothConnectedIcon color="white" fontSize="large" />}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                size="small"
                              >
                                Connect Bluetooth
                              </MDTypography>
                            </Button>
                          </MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="light"
                            color="info"
                            sx={{ mt: 1, ml: 1 }}
                          >
                            {BTDevice ? `Connected: ${BTDevice}` : "Disconnected"}
                          </MDTypography>
                        </>
                      ) : null}
                    </MDBox>
                    <LoadingButton
                      loadingPosition="center"
                      loading={confirmSubmit.isValidating}
                      loadingIndicator={
                        <CircularProgress
                          sx={{ color: "#0000CE" }}
                          role="progressbar"
                          thickness={10}
                          color="info"
                          size={20}
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmissionRequested(true);
                      }}
                      variant="contained"
                      sx={{ width: "100%", mt: 2 }}
                      endIcon={<SendIcon color="white" fontSize="large" />}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        size="small"
                      >
                        Proceed Return
                      </MDTypography>
                    </LoadingButton>
                  </MDBox>
                  {selectedScanner === "Camera/Webcam" ? (
                    <MDBox mt={1} mb={1}>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                          <BarcodeScannerComponent
                            key={scannerRemountToken}
                            style={{ maxWidth: "100%" }}
                            delay={500}
                            onUpdate={handleQRScanResult}
                            stopStream={selectedScanner !== "Camera/Webcam"}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1}>
                    <MDTypography variant="text" hidden>
                      {errorMessage}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Sales;
