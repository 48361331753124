import * as React from "react";
import { Icon, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";

const currencyFormatIdrField = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      prefix="IDR. "
    />
  );
});

currencyFormatIdrField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const renderSalesDataList = (
  data,
  qtyChangeCallback,
  discountChangeCallback,
  deleteCallback,
  showPriceAndDiscount
) => (
  <div>
    {data.map((x) => (
      <Paper
        key={x.item_id}
        elevation={0}
        sx={{ p: 1, borderRadius: "5px", borderColor: x.err_message ? "red" : "black" }}
        variant="outlined"
      >
        <Grid container>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <MDTypography>{x.product_name}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography>Variant: {x.stock_tags}</MDTypography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4} alignContent="center">
            <Grid container item xs={9} alignContent="center" justifyContent="center">
              {showPriceAndDiscount && (
                <TextField
                  label="Price"
                  name="sell-price"
                  value={x.sell_price}
                  InputProps={{
                    inputComponent: currencyFormatIdrField,
                    readOnly: true,
                  }}
                  sx={{ margin: "0.15em" }}
                />
              )}
              <TextField
                sx={{ margin: "0.15em" }}
                label="Quantity"
                type="number"
                value={x.qty}
                min={1}
                onChange={(e) => qtyChangeCallback(x.item_id, parseInt(e.target.value, 10))}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Grid>
            <Grid container item xs={3} alignContent="center" justifyContent="center">
              {showPriceAndDiscount && (
                <TextField
                  sx={{ margin: "0.15em" }}
                  name="discount"
                  label="Discount"
                  value={x.discount}
                  onChange={(e) => discountChangeCallback(x.item_id, parseInt(e.target.value, 10))}
                  InputProps={{
                    inputComponent: currencyFormatIdrField,
                  }}
                />
              )}
              <MDButton
                iconOnly
                color="error"
                onClick={deleteCallback && (() => deleteCallback(x.item_id))}
                variant="outlined"
                sx={{ margin: "0.15em" }}
              >
                <Icon fontSize="medium" component="i" color="error">
                  delete
                </Icon>
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    ))}
  </div>
);

export default renderSalesDataList;
